type Method = 'GET' | 'POST' | 'PUT' | 'DELETE'

/**
 * Fetches data from a given API endpoint.
 * 
 * @param url The URL of the API endpoint to fetch data from.
 * @param method The HTTP method to use for the request.
 * @param token The authentication token to use for the request.
 * @param errorMessage The error message to throw if the request fails.
 * @param body The data to send in the request body.
 * @param accept The MIME type to accept in the response.
 * @returns A Promise that resolves to a Blob containing the response data.
 */
export async function fetchFromApi<T>(url: string, method: Method, token: string, errorMessage: string, body?: T, accept = 'application/json'): Promise<Blob> {
  const headers = new Headers({
    'Authorization': `Bearer ${token}`,
    'Accept': accept,
  })

  if (body) {
    headers.append('Content-Type', 'application/json')
  }
  
  const response = await fetch(url, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  })

  if (!response.ok) {
    throw new Error(errorMessage)
  }

  return await response.blob()
}
