export const mockNaicsCode = [
  {
    id: '110000',
    category: 'AGRICULTURE',
    subCategory: 'MISCELLANEOUS - LAWN CARE AND SERVICES',
  },
  {
    id: '111199',
    category: 'AGRICULTURE',
    subCategory: 'ALL GRAIN FARMING',
  },
  {
    id: '111200',
    category: 'AGRICULTURE',
    subCategory: 'VEGETABLE AND MELON FARMING',
  },
  {
    id: '111400',
    category: 'AGRICULTURE',
    subCategory: 'GREENHOUSE, NURSEY PRODUCTION',
  },
  {
    id: '112300',
    category: 'AGRICULTURE',
    subCategory: 'POULTRY AND EGG PRODUCTION',
  },
  {
    id: '210000',
    category: 'MINING',
    subCategory: 'MISCELLANEOUS',
  },
  {
    id: '212200',
    category: 'MINING',
    subCategory: 'METAL ORE MINING',
  },
  {
    id: '212300',
    category: 'MINING',
    subCategory: 'NONMETALLIC MINERAL MINING AND QUARRYING',
  },
  {
    id: '213100',
    category: 'MINING',
    subCategory: 'SUPPORT ACTIVITIES FOR MINING',
  },
  {
    id: '213112',
    category: 'MINING',
    subCategory: 'SUPPORT ACTIVITIES FOR OIL AND GAS OPS',
  },
]

export const mockNaicsCategories = [
  {
    id: 'AGRICULTURE',
    category: 'AGRICULTURE',
  },
  {
    id: 'ARTS ENTERTAINMENT',
    category: 'ARTS ENTERTAINMENT',
  },
  {
    id: 'CONSTRUCTION',
    category: 'CONSTRUCTION',
  },
  {
    id: 'EDUCATIONAL SERVICES',
    category: 'EDUCATIONAL SERVICES',
  },
  {
    id: 'FINANCE/INSURANCE',
    category: 'FINANCE/INSURANCE',
  },
  {
    id: 'FOOD SEVICES/LODGING HOSPITALITY',
    category: 'FOOD SEVICES/LODGING HOSPITALITY',
  },
  {
    id: 'HEALTH CARE',
    category: 'HEALTH CARE',
  },
  {
    id: 'INFORMATION/IT',
    category: 'INFORMATION/IT',
  },
  {
    id: 'MANAGEMENT COMP',
    category: 'MANAGEMENT COMP',
  },
  {
    id: 'MANUFACTURING',
    category: 'MANUFACTURING',
  },
  {
    id: 'MINING',
    category: 'MINING',
  },
  {
    id: 'OTHER SERVICES',
    category: 'OTHER SERVICES',
  },
  {
    id: 'PROFESSIONAL SERVICES',
    category: 'PROFESSIONAL SERVICES',
  },
  {
    id: 'PUBLIC ADMINISTRATION UNDECLARED ',
    category: 'PUBLIC ADMINISTRATION UNDECLARED ',
  },
  {
    id: 'REAL ESTATE',
    category: 'REAL ESTATE',
  },
  {
    id: 'RETAIL TRADE',
    category: 'RETAIL TRADE',
  },
  {
    id: 'TRANSPORTATION/WAREHOUSING',
    category: 'TRANSPORTATION/WAREHOUSING',
  },
  {
    id: 'UTILITIES',
    category: 'UTILITIES',
  },
  {
    id: 'WHOLESALE',
    category: 'WHOLESALE',
  },
]
