import Button from '@mui/material/Button'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAuth from '../../../../hooks/useAuth'
import { useSnackbar } from '@/components/Snackbar'
import { downloadBlobToFile } from '@/utils'
import { fetchAccountCommentReport } from '@/utils/api'

interface Props {
  accountId: number
  disabled?: boolean
}

const AccountCommentsReportButton: React.FC<Props> = ({ accountId, disabled }) => {
  const { t } = useTranslation()
  const { openSnackbar } = useSnackbar()
  const { authInformation } = useAuth()
  const [isLoading, setIsLoading] = useState(false)

  const handleDownload = async (): Promise<void> => {
    try {
      setIsLoading(true)

      const blob = await fetchAccountCommentReport(accountId, authInformation.token)

      if (blob) {
        const filename = t('accountCommentsReport').replace(/ /g, '') + accountId

        downloadBlobToFile(blob, filename, 'csv')
        openSnackbar({
          message: t('fileDownloaded', { filename: t('accountCommentsReport') }),
          severity: 'success',
        })
      }
    } catch (error) {
      openSnackbar({
        message: error instanceof Error ? error.message : t('oopsSomethingWentWrong'),
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button  variant="contained" color="primary" onClick={handleDownload} disabled={isLoading || disabled}>
      {t('exportItem', { item: t('accountCommentsReport') })}
    </Button>
  )
}

export default AccountCommentsReportButton
