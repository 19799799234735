import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../../components/PageTitle'
import {
  SearchBusinessAccount,
  SearchBusinessAccountInput,
  useSearchBusinessAccountQuery,
} from '@/generated/graphql'
import AccountCommentsReportButton from '@/pages/ReportModule/AccountCommentsReport/Button/AccountCommentReportButton'
import { omitNullEmptyOrUndefined } from '@/utils'

function getOptionValue (option: SearchBusinessAccount): string {
  return `${option.accountNbr} - ${option.businessName}`
}
export default function AccountCommentsReport(): JSX.Element {
  const { t } = useTranslation()
  const [accountNumber, setAccountNumber] = useState<string>('')
  const [selectedAccount, setSelectedAccount] = useState<SearchBusinessAccount | null>(null)

  const handleAccountChange = (_event: React.ChangeEvent<EventTarget>, value: SearchBusinessAccount | null): void => {
    setSelectedAccount(value)
  }

  const { data, isLoading, isRefetching } = useSearchBusinessAccountQuery(
    {
      searchFilters: {
        ...omitNullEmptyOrUndefined<SearchBusinessAccountInput>({
          accountNumber: accountNumber.toString(),
        }),
      },
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      enabled: !!accountNumber,
    }
  )

  const businessAccounts = React.useMemo(() => {
    if (isLoading || isRefetching || !data?.searchBusinessAccount) {
      return []
    }

    const validAccounts = data.searchBusinessAccount.searchBusinessAccount.filter(
      (account): boolean => account !== null,
    ) as SearchBusinessAccount[]

    return validAccounts
  }, [data?.searchBusinessAccount, isLoading, isRefetching])

  const isPageBusy = isLoading || isRefetching

  return (
    <>
      <Box marginBottom={2}>
        <PageTitle title={t('accountCommentsReport')} />
      </Box>
      <Stack component={Paper} alignItems="flex-start" elevation={1} p={3} width="98%">
        <Typography variant="h6" fontWeight="bold">
          {t('accountCommentReportDescription')}
        </Typography>
        <Box component="form" width="100%" marginY={2}>
          <Autocomplete
            options={businessAccounts}
            getOptionLabel={getOptionValue}
            onInputChange={(_event, newInputValue): void => setAccountNumber(newInputValue)}
            onChange={handleAccountChange}
            loading={!!accountNumber && isPageBusy}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                label={t('accountNumber')}
                variant="standard"
                margin="dense"
                fullWidth
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {!!accountNumber && (isLoading || isRefetching) ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <AccountCommentsReportButton disabled={!accountNumber} accountId={Number(selectedAccount?.accountId)} />
          </Box>
        </Box>
      </Stack>
    </>
  )
}


