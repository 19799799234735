import Typography from '@mui/material/Typography'
import React from 'react'

interface Props {
  title: string
}

export default function PageTitle({ title }: Props): JSX.Element {
  return (
    <Typography variant="h3" component="h3" sx={{ typography: { xs: 'h5', sm: 'h4', md: 'h3' } }}>
      {title}
    </Typography>
  )
}
