import { UseQueryResult } from '@tanstack/react-query'
import React from 'react'
import type { GetCurrentTaxYearQuery } from '../generated/graphql'

import useGetCurrentTaxYear from './useGetCurrentTaxYear'

interface GlobalContextProviderProps {
  children: React.ReactNode
}

interface GlobalContext {
  currentTaxYear: number
  currentTaxYearMinDate: Date
  currentTaxYearMaxDate: Date
  getCurrentTaxYearQueryResponse: UseQueryResult<GetCurrentTaxYearQuery>
}

const GlobalContext = React.createContext<GlobalContext>({} as GlobalContext)

export function GlobalContextProvider({ children }: GlobalContextProviderProps): JSX.Element {
  const [currentTaxYear, setCurrentTaxYear] = React.useState<number>(new Date().getFullYear())

  const currentTaxYearMaxDate = React.useMemo(() => {
    return getMaxDateForYear(currentTaxYear)
  }, [currentTaxYear])

  const currentTaxYearMinDate = React.useMemo(() => {
    return getMinDateForYear(currentTaxYear)
  }, [currentTaxYear])

  const getCurrentTaxYearQueryResponse = useGetCurrentTaxYear({ setCurrentTaxYear })

  const authContext = {
    currentTaxYear,
    currentTaxYearMaxDate,
    currentTaxYearMinDate,
    getCurrentTaxYearQueryResponse,
  }

  return <GlobalContext.Provider value={authContext}>{children}</GlobalContext.Provider>
}

function getMaxDateForYear(year: number = new Date().getFullYear()): Date {
  return new Date(`${year}-12-31T23:59:59.999Z`)
}

function getMinDateForYear(year: number = new Date().getFullYear()): Date {
  return new Date(`${year}-01-01T00:00:00.000Z`)
}

export default function useGlobalContext(): GlobalContext {
  return React.useContext(GlobalContext)
}
